import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import {
  ConfidenceResult,
  FluencyResult,
  IntonationResult,
  PronunciationResult,
  ResultCategoryName,
  validateResultCategoryName,
  VocabularyResult,
  Vocab_AccuracyResult,
} from '../../types/report';
import { routes } from '../../router';
import { getCategoryData } from '../../data/report';
import { getReportsByStudent, getStatisticBySchoolCode } from '../../api';
import { Container, Loading } from '../../components/base';
import Pronunciation from './components/Pronunciation';
import Fluency from './components/Fluency';
import Intonation from './components/Intonation';
import Vocabulary from './components/Vocabulary';
import HeadingSection from './components/HeadingSection';
import Breadcrumbs from './components/Breadcrumbs';
import {
  FluencyStatistic,
  IntonationStatistic,
  PronunciationStatistic,
  VocabularyStatistic,
} from '../../types/reportStatistic';

const categoryComponentMap: any = {
  [ResultCategoryName.Pronunciation]: Pronunciation,
  [ResultCategoryName.Fluency]: Fluency,
  [ResultCategoryName.Intonation]: Intonation,
  [ResultCategoryName.Vocabulary]: Vocabulary,
};

type CategoryResult =
  | PronunciationResult
  | FluencyResult
  | IntonationResult
  | VocabularyResult
  | ConfidenceResult
  | Vocab_AccuracyResult;

type CategoryStatistic =
  | PronunciationStatistic
  | FluencyStatistic
  | IntonationStatistic
  | VocabularyStatistic;

const ReportDetailCategory = () => {
  const params = useParams();
  const categoryType = params.category;
  const isCategoryTypeValid = categoryType && validateResultCategoryName(categoryType);

  const [loading, setLoading] = React.useState(false);
  const [categoryResult, setCategoryResult] = React.useState<CategoryResult | undefined>(undefined);
  const [categoryStatistic, setCategoryStatistic] = React.useState<CategoryStatistic | undefined>(
    undefined
  );

  React.useEffect(() => {
    const getReportData = async () => {
      if (params.reportId) {
        try {
          const report = await getReportsByStudent(params.reportId);
          if (report && isCategoryTypeValid) {
            const categoryResult: CategoryResult | undefined =
              report?.result.category[categoryType];
            setCategoryResult(categoryResult);
          }
          const schoolStatistic = await getStatisticBySchoolCode(report.student.schoolCode);
          if (schoolStatistic && isCategoryTypeValid) {
            const categoryStatistic: CategoryStatistic | undefined =
              schoolStatistic.category[categoryType];
            setCategoryStatistic(categoryStatistic);
          }
        } catch (error: any) {
          console.error(error);
        }
      }
    };
    setLoading(true);
    getReportData();
    setLoading(false);
  }, [params.reportId, categoryType, isCategoryTypeValid]);

  if (!isCategoryTypeValid) {
    return <Navigate to={routes.ReportDetail.getRoute(params.reportId)} />;
  }

  const categoryData = getCategoryData(categoryType);
  const CategoryComponent = categoryComponentMap[categoryType];

  if (loading)
    return (
      <Container className="p-8">
        <Loading />
      </Container>
    );
  if (!categoryResult) return null;
  return (
    <>
      {params.reportId && <Breadcrumbs reportId={params.reportId} />}
      <HeadingSection
        titleTh={categoryData.title.th}
        titleEn={categoryData.title.en}
        score={categoryResult.score}
        averageScore={categoryStatistic?.mean}
        fullScore={5}
        color={categoryData.color}
        text={categoryResult.analysisText}
      />
      <CategoryComponent result={categoryResult} statistic={categoryStatistic} />
    </>
  );
};

export default ReportDetailCategory;
