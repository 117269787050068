import { Button, Container, Heading, Text } from '../../../components/base';
import { routes } from '../../../router';
import { ResultCategoryName, ResultCategory } from '../../../types/report';
import { getCategoryData } from '../../../data/report';
import { formatScore, toClassNames } from '../../../utils';

type CategoryItemProps = {
  reportId: string;
  type: ResultCategoryName;
  score: number;
  fullScore: number;
};
const CategoryItem = ({ reportId, type, score, fullScore }: CategoryItemProps) => {
  const categoryData = getCategoryData(type);
  const linkUrl = routes.ReportDetailCategory.getRoute({
    reportId: reportId,
    category: type,
  });

  return (
    <div className={'flex gap-3 md:gap-6 justify-center'}>
      <div
        className={toClassNames([
          'w-[112px] h-[112px] rounded-xl flex flex-col justify-center items-center text-primary shrink-0',
        ])}
        style={{ backgroundColor: categoryData.color }}
      >
        <Text variant="3xl" className="font-bold">
          {formatScore(score)}
        </Text>
      </div>
      <div className="flex flex-col justify-center w-[150px]">
        <Heading variant="h5" className="text-primary mb-3">
          {categoryData.title.th} <br /> ({categoryData.title.en})
        </Heading>

        <Button variant="primary" isRouterLink={true} to={linkUrl}>
          ดูรายละเอียด
        </Button>
      </div>
    </div>
  );
};

type CategoryResultSectionProps = {
  reportId: string;
  categoryResult: ResultCategory;
};
const CategoryResultSection = ({ categoryResult, reportId }: CategoryResultSectionProps) => {
  return (
    <Container className="py-10">
      <Heading variant="h2" className="text-primary text-center mb-8">
        ผลการประเมินตามตัวชี้วัด
      </Heading>
      <div className="flex flex-col gap-6">
        <CategoryItem
          reportId={reportId}
          type={ResultCategoryName.Fluency}
          score={categoryResult[ResultCategoryName.Fluency].score}
          fullScore={5}
        />
        <hr />
        <CategoryItem
          reportId={reportId}
          type={ResultCategoryName.Vocabulary}
          score={categoryResult[ResultCategoryName.Vocabulary].score}
          fullScore={5}
        />
        <hr />
        <CategoryItem
          reportId={reportId}
          type={ResultCategoryName.Pronunciation}
          score={categoryResult[ResultCategoryName.Pronunciation].score}
          fullScore={5}
        />
        {/* <hr />
        <CategoryItem
          reportId={reportId}
          type={ResultCategoryName.Intonation}
          score={categoryResult[ResultCategoryName.Intonation].score}
          fullScore={5}
        /> */}
      </div>
    </Container>
  );
};

export default CategoryResultSection;
