import { Report } from '../types/report';
import { School } from '../types/school';
import { SchoolReportStatistic } from '../types/reportStatistic';
import { getRequest } from './utils';

const BASE_URL = 'https://edsy-assess-backend-vg5zhtok2a-as.a.run.app';
// const BASE_URL = 'http://127.0.0.1:8877';

export const getReportsByStudent = async (studentId: string): Promise<Report> => {
  try {
    const fetchUrl = new URL(`/students/${studentId}/reports`, BASE_URL);
    const data = await getRequest(fetchUrl);

    /**
     * This is a workaround to handle the first round of test which will have only one result.
     * TODO: Refactor this and types to expect multiple results.
     */
    const studentInfo = data.student;
    const latestResult = data.results[0];
    return {
      dbId: data.databaseId,
      id: studentId,
      date: new Date(latestResult.date),
      student: studentInfo,
      result: latestResult,
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getReportsBySchool = async (schoolCode: string): Promise<Report[] | undefined> => {
  try {
    const fetchUrl = new URL(`/schools/${schoolCode}/reports`, BASE_URL);
    const data = await getRequest(fetchUrl);
    /**
     * TODO: Write a function to parse data from api to ensure strict type.
     */
    return data.reports
      .filter((d: any) => Object.keys(d.result).length > 0)
      .map((d: any) => {
        return {
          dbId: d.databaseId,
          id: d.student.studentId,
          date: new Date(d.result.date),
          student: d.student,
          result: d.result,
        };
      });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getStatisticBySchoolCode = async (
  schoolCode: string
): Promise<SchoolReportStatistic | undefined> => {
  try {
    const fetchUrl = new URL(`/schools/${schoolCode}/statistic`, BASE_URL);
    const data = await getRequest(fetchUrl);
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const validateStudentId = async (id: string): Promise<boolean> => {
  try {
    const fetchUrl = new URL('check-student-id', BASE_URL);
    fetchUrl.search = new URLSearchParams({ studentId: id }).toString();
    await getRequest(fetchUrl);
    return true;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const validateSchoolCode = async (code: string): Promise<boolean> => {
  try {
    const fetchUrl = new URL('check-code', BASE_URL);
    fetchUrl.search = new URLSearchParams({ code }).toString();
    const data = await getRequest(fetchUrl);
    return data.name;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getSchoolDataBySchoolCode = async (code: string): Promise<School> => {
  try {
    const fetchUrl = new URL(`schools/${code}`, BASE_URL);
    const data = await getRequest(fetchUrl);
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
