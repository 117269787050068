import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../router';
import { Container, Image } from '../../components/base';

const MainLayout = ({ children }: { children?: React.ReactNode }) => {
  return (
    <div className="bg-primary h-[80px] flex items-center">
      <Container fluid={true}>
        <Link to={routes.Home.getRoute()}>
          <Image src="/assets/images/edsy-logo-white.png" alt="edsy" className="w-[96px]" />
        </Link>
        {children}
      </Container>
    </div>
  );
};

export default MainLayout;
