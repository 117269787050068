import dayjs from 'dayjs';
import { Sex } from '../../../types/report';
import style from '../SummaryReportPrint.module.css';

type StudentInfoProps = {
  fullName: string;
  school: string;
  grade: string;
  room?: string;
  sex: Sex;
  testDate: Date;
  testerId?: string;
};

const StudentInfo = ({
  sex,
  fullName,
  school,
  grade,
  room,
  testDate,
  testerId,
}: StudentInfoProps) => {
  return (
    <div className={style['report-print-student-info']}>
      <div className={style['report-print-mascot-container']}>
        <img
          src={'/assets/images/rocket-avatar.svg'}
          alt="mascot"
          className={style['report-print-mascot']}
        />
      </div>
      <div>
        <p>
          <span style={{ fontWeight: 600 }}>ชื่อ:</span> {fullName}
        </p>
        <p>
          <span style={{ fontWeight: 600 }}>โรงเรียน:</span> {school}
        </p>
        <p>
          <span style={{ fontWeight: 600 }}>ระดับชั้น:</span> {grade} {room ? `ห้อง ${room}` : null}
        </p>
        <p>
          <span style={{ fontWeight: 600 }}>วันที่สอบ:</span>{' '}
          {dayjs(testDate).format('D MMMM BBBB')}
        </p>
        {testerId && (
          <p>
            <span style={{ fontWeight: 600 }}>ID:</span> {testerId}
          </p>
        )}
      </div>
    </div>
  );
};

export default StudentInfo;
