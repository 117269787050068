import React from 'react';
import { useParams } from 'react-router-dom';
import { getReportsBySchool, getSchoolDataBySchoolCode } from '../../api';
import { Report } from '../../types/report';
import { routes } from '../../router';
import { Container, TextArea, Button, Heading, Text } from '../../components/base';
import style from './SchoolReports.module.css';
import { School } from '../../types/school';
import { toClassNames } from '../../utils';
import SummaryReportPrint, {
  SummaryReportTemplate,
  getTemplateType,
} from '../../components/SummaryReportPrint';

type PrintReport = {
  id: string;
  report?: Report;
};

const SchoolReports = () => {
  const params = useParams();
  const [loading, setLoading] = React.useState(true);
  const [reports, setReports] = React.useState<Report[] | undefined>(undefined);
  const [school, setSchool] = React.useState<School>();
  const [printIdsStr, setPrintIdsStr] = React.useState<string>('');
  const [printReports, setPrintReports] = React.useState<PrintReport[]>();
  const [templateType, setTemplateType] = React.useState<SummaryReportTemplate>(
    SummaryReportTemplate.template1
  );

  React.useEffect(() => {
    const getReportData = async () => {
      if (params.schoolCode) {
        try {
          const [reports, schoolData]: [report: Report[] | undefined, schoolData: School] =
            await Promise.all([
              getReportsBySchool(params.schoolCode),
              getSchoolDataBySchoolCode(params.schoolCode),
            ]);
          setReports(reports);
          setSchool(schoolData);
          setLoading(false);
        } catch (error: any) {
          alert(error.message);
        }
      }
    };
    getReportData();
  }, [params.schoolCode]);

  const handleChangePrintIds = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setPrintIdsStr(e.currentTarget.value);
  };

  const handleUpdatePrintReports = () => {
    const printIds = printIdsStr.split('\n');
    const formattedPrintReports = printIds.map((id) => {
      const report = reports?.find((r) => r.dbId === id);
      return {
        id: id.trim(),
        report: report,
      };
    });
    setPrintReports(formattedPrintReports);
  };

  const handlePrintSelectedReports = () => {
    window.print();
  };

  const handleChangeTemplate = (e: React.FormEvent<HTMLSelectElement>) => {
    const templateType = getTemplateType(e.currentTarget.value);
    setTemplateType(templateType);
  };

  if (loading || !reports || !school) return <h2>loading...</h2>;
  return (
    <>
      <Container fluid className={toClassNames([style['web-container'], 'py-12'])}>
        <Heading variant={'h1'} className="mt-4 mb-4 text-2xl font-semibold">
          Report for school ID: {params.schoolCode}
        </Heading>
        <div className="mb-12">
          <label>Choose a Template to print:</label>
          <select
            name="templates"
            id="templates"
            value={templateType}
            onChange={handleChangeTemplate}
            className={'bg-primary-100 py-1 px-2 mx-2 rounded-full cursor-pointer'}
          >
            {Object.values(SummaryReportTemplate).map((templateName) => (
              <option key={templateName} value={templateName}>
                {templateName}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-12 border-b border-black pb-12">
          <div className="flex gap-4 items-center">
            <Button
              variant="primary"
              to={routes.SchoolReportsPrint.getRoute({
                schoolCode: params.schoolCode,
                templateType: templateType,
              })}
              isRouterLink={true}
              target="_blank"
            >
              Print all {reports.length} reports
            </Button>
            <Text>or select reports to print below</Text>
          </div>
        </div>

        <Heading variant={'h2'} className={'mb-4'}>
          Select reports to print
        </Heading>
        <TextArea
          value={printIdsStr}
          onChange={handleChangePrintIds}
          placeholder={'กรอก id ของ report ที่ต้องการจะปริ้นโดยเว้นบรรทัดระหว่าง id'}
          className={'min-h-[150px] w-[450px] mb-4'}
        />
        <Button variant="outlined" onClick={handleUpdatePrintReports} className={'mb-8'}>
          Find reports
        </Button>

        <Heading variant={'h3'} className={'mb-4'}>
          Print details
        </Heading>

        {printReports && (
          <div>
            <Text className="mb-4">Total reports to print: {printReports?.length || 0}</Text>
            <Button
              variant="primary"
              size={'large'}
              onClick={handlePrintSelectedReports}
              className={'mb-8'}
            >
              Print selected reports
            </Button>
            <table className={style.table}>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>status</th>
                </tr>
              </thead>
              <tbody>
                {printReports.map((r) => (
                  <tr key={r.id}>
                    <td>{r.id}</td>
                    <td>{r.report?.student.fullName}</td>
                    <td>{!!r.report ? 'Yes' : 'No'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Container>
      <div className={style['print-container']}>
        {printReports?.map((r) => {
          return (
            <SummaryReportPrint
              report={r.report}
              reportId={r.id}
              key={r.id}
              school={school}
              template={templateType}
            />
          );
        })}
      </div>
    </>
  );
};

export default SchoolReports;
