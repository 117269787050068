import { Container, Heading, Text } from '../../../components/base';
import ScoreCircleProgress from './ScoreCircleProgress';

const LevelPill = ({ text }: { text: string }) => {
  return <div className="py-1 px-6 bg-primary text-white font-bold rounded-full">{text}</div>;
};

type OverallResultSectionProps = {
  testerId: string;
  letterGrade: string;
  score: number;
  analysisText: string;
  cefrResult: string;
  cambridgeResult: string;
};
const OverallResultSection = ({
  testerId,
  score,
  analysisText,
  cefrResult,
  cambridgeResult,
}: OverallResultSectionProps) => {
  return (
    <Container className="border-b py-10">
      <Heading variant="h2" className="text-primary text-center mb-1">
        ผลการวัดระดับของคุณคือ
      </Heading>
      <Heading variant="h6" className="text-grey-500 text-center font-normal mb-12">
        รหัสประจำตัว: {testerId}
      </Heading>

      <Heading variant="h5" className="mb-4 text-center">
        ระดับเทียบตามมาตรฐานสากล
      </Heading>

      <div className="flex w-full justify-center mb-16">
        <div className="flex flex-col items-center gap-4 py-2 px-5 border-r">
          <div className="grow flex items-center">
            <img src="/assets/images/cefr-logo.png" alt="cefr assessment" className="w-[96px]" />
          </div>
          <LevelPill text={cefrResult} />
        </div>
        <div className="flex flex-col items-center gap-4 py-2 px-5">
          <div className="grow flex items-center">
            <img
              src="/assets/images/cambridge-assessment-logo.png"
              alt="cambridge assessment"
              className="w-[150px]"
            />
          </div>
          <LevelPill text={cambridgeResult} />
        </div>
      </div>

      <Heading variant="h5" className="mb-4 text-center">
        คะแนนตามมาตรฐาน EdSpeak
      </Heading>

      <div className="flex justify-center w-full mb-6">
        <ScoreCircleProgress score={score} fullScore={5} />
      </div>

      <Text className="mb-8 text-center">{analysisText}</Text>
    </Container>
  );
};

export default OverallResultSection;
