import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { getReportsBySchool, getSchoolDataBySchoolCode } from '../../api';
import { Report } from '../../types/report';
import { School } from '../../types/school';
import SummaryReportPrint, { getTemplateType } from '../../components/SummaryReportPrint';

const SchoolReportsPrint = () => {
  const params = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const templateTypeStr = query.get('templateType') || '';
  const [loading, setLoading] = React.useState(true);
  const [reports, setReport] = React.useState<Report[]>();
  const [school, setSchool] = React.useState<School>();

  React.useEffect(() => {
    const getReportData = async () => {
      if (params.schoolCode) {
        try {
          const [report, schoolData]: [report: Report[] | undefined, schoolData: School] =
            await Promise.all([
              getReportsBySchool(params.schoolCode),
              getSchoolDataBySchoolCode(params.schoolCode),
            ]);
          setReport(report);
          setSchool(schoolData);
          setLoading(false);
        } catch (error: any) {
          alert(error.message);
        }
      }
    };
    getReportData();
  }, [params.schoolCode]);

  if (loading || !reports || !school) return <h2>loading...</h2>;
  return (
    <>
      {reports.map((report) => {
        return (
          <SummaryReportPrint
            template={getTemplateType(templateTypeStr)}
            report={report}
            reportId={report.id}
            key={report.id}
            school={school}
          />
        );
      })}
    </>
  );
};

export default SchoolReportsPrint;
