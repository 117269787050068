import { BaseProps } from '../types';
import { toClassNames } from '../../utils';

interface ContainerProps extends BaseProps {
  fluid?: boolean;
}

const Container = ({ fluid = false, className, children }: ContainerProps) => {
  return (
    <div
      className={toClassNames([
        'container px-4 md:px-8 lg:px-12',
        `${!fluid && 'max-w-[768px]'}`,
        className || '',
      ])}
    >
      {children}
    </div>
  );
};

export default Container;
