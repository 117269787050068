import { Container, Button } from '../../../components/base';
import { routes } from '../../../router';

const Breadcrumbs = ({ reportId }: { reportId: string }) => {
  return (
    <Container className="pt-8">
      <Button
        variant="flatgrey"
        size="large"
        isRouterLink={true}
        to={routes.ReportDetail.getRoute(reportId)}
        className="!p-0"
        data-analytic-id="category-breadcrumb-back"
      >
        {'< กลับไปหน้าหลัก'}
      </Button>
    </Container>
  );
};

export default Breadcrumbs;
