import { Report } from '../../types/report';
import { Image } from '../../components/base';
import TesterInfoSection from './components/TesterInfoSection';
import OverallResultSection from './components/OverallResultSection';
import CategoryResultSection from './components/CategoryResultSection';
import { School } from '../../types/school';

const ReportDetail = ({ report, school }: { report: Report; school?: School }) => {
  const bannerLink = school?.fullReportAdsLink;
  const bannerUrlDesktop = school?.fullReportAdsUrl.desktop;
  const bannerUrlMobile = school?.fullReportAdsUrl.mobile;
  const shouldShowBanner = bannerUrlDesktop && bannerUrlMobile && bannerLink;
  return (
    <>
      <TesterInfoSection
        fullName={report.student.fullName}
        schoolName={report.student.school}
        grade={report.student.grade}
        room={report.student.room}
        testDate={report.date}
      />

      <OverallResultSection
        testerId={report.id}
        letterGrade={report.result.overall.letterGrade}
        score={report.result.overall.score}
        analysisText={report.result.overall.resultAnalysisText}
        cefrResult={report.result.englishStandard.cefr}
        cambridgeResult={report.result.englishStandard.cambridge}
      />

      <CategoryResultSection reportId={report.id} categoryResult={report.result.category} />

      {shouldShowBanner && (
        <div>
          <a href={bannerLink} rel="noreferrer noopener" target={'_blank'}>
            <Image src={bannerUrlMobile} alt="main-banner" className="block sm:hidden w-full" />
            <Image src={bannerUrlDesktop} alt="main-banner" className="hidden sm:block w-full" />
          </a>
        </div>
      )}
    </>
  );
};

export default ReportDetail;
