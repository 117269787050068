import { Container, Heading, Text } from '../../../components/base';
import { VocabularyResult } from '../../../types/report';
import Banner from './Banner';

/**
 * TODO: Refactor and Move these colors to theme if they are used somewhere else.
 */
const graphColors: string[] = ['#253B7F', '#334D9C', '#4989B6', '#5ABCCC', '#82DBDF', '#AFF6F1'];
const DistributionGraph = ({ data }: { data: { key: string; value: number }[] }) => {
  return (
    <div className="w-full flex flex-col h-[240px] max-w-[320px] md:h-[340px] md:max-w-[400px]">
      <div className="flex grow gap-3 md:gap-5 w-full mb-4 border-t border-grey-400 border-dashed">
        {data.map((d, index) => {
          const val = d.value > 0 ? d.value : 0;
          return (
            <div className="grow basis-0 flex flex-col h-full items-center" key={d.key}>
              <div className="grow basis-0 flex flex-col w-full mb-2">
                <div
                  className="w-full rounded-full mt-auto"
                  style={{ backgroundColor: graphColors[index], height: `${val + 2}%` }}
                ></div>
              </div>
              <div
                className="w-full pt-[100%] rounded-full text-white flex justify-center items-center relative mb-1"
                style={{ backgroundColor: graphColors[index] }}
              >
                <Text
                  variant="sm"
                  className="text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 font-semibold"
                >
                  {d.key.toUpperCase()}
                </Text>
              </div>
              <Text variant="xs" className="font-semibold">
                {Math.floor(val)}%
              </Text>
            </div>
          );
        })}
      </div>
      <div className="flex justify-between items-center">
        <Text variant="xs" className="text-grey-500">
          ศัพท์ระดับพื้นฐาน
        </Text>
        <Text variant="xs" className="text-grey-500">
          ศัพท์ระดับสูง
        </Text>
      </div>
    </div>
  );
};

const Vocabulary = ({ result: { vocabularyLevel } }: { result: VocabularyResult }) => {
  return (
    <>
      <Banner
        mobileImageUrl="/assets/images/marketing/vocabulary-banner-mobile.png"
        tabletImageUrl="/assets/images/marketing/vocabulary-banner-tablet.png"
        alt="vocabulary-banner"
        href="https://student.edsy.co/"
        dataAnalyticId="category-banner-vocabulary"
      />
      <div className="bg-primary-50">
        <Container className="py-10 flex flex-col items-center gap-6">
          <Heading variant="h4" className="text-center">
            การกระจายตัวของระดับคำศัพท์ <br />
            (ตามมาตรฐาน CEFR)
          </Heading>
          <div className="w-full px-10 py-6 rounded-xl bg-white flex justify-center">
            <DistributionGraph data={vocabularyLevel.distribution} />
          </div>
        </Container>
      </div>
      <Container className="py-10 flex flex-col items-center">
        <Heading variant="h3" className="mb-6 text-primary">
          วิเคราะห์ระดับคำศัพท์ที่ใช้
        </Heading>
        <Text className="text-center">{vocabularyLevel.analysisText}</Text>
      </Container>
    </>
  );
};

export default Vocabulary;
