import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { Text } from '../../../components/base';
import { formatScore } from '../../../utils';

/**
 * TODO: Refactor and Move these colors to theme if they are used somewhere else.
 */
const graphColors = ['#AFF6F1', '#82DBDF', '#5ABCCC', '#4989B6', '#334D9C', '#253B7F'] as const;
const baseGraphColor = '#D9D9D9' as const;

type ProgressDataElement = {
  color: typeof graphColors[number] | typeof baseGraphColor;
  name: string;
  value: number;
  stroke: string;
  strokeWidth: number;
};
const getProgressDataFromScore = (score: number, fullScore: number) => {
  const totalCellCount = fullScore + 1; // Plus 1 for the split cell
  const fullCellCount = Math.floor(score);
  const decimalCellValue = score % 1;

  const data: ProgressDataElement[] = [];
  for (let index = 1; index <= totalCellCount; index++) {
    let color, value;
    let hasStroke = true;
    if (index <= fullCellCount) {
      // Full cell
      color = graphColors[index % graphColors.length];
      value = 1;
    } else if (index === fullCellCount + 1) {
      // Decimal cell
      color = graphColors[index % graphColors.length];
      value = decimalCellValue;
      hasStroke = false;
    } else if (index === fullCellCount + 2) {
      // Blank cell after Decimal cell
      color = baseGraphColor;
      value = 1 - decimalCellValue;
      hasStroke = false;
    } else {
      // Blank cell
      color = baseGraphColor;
      value = 1;
    }

    data.push({
      name: (index + 1).toString(),
      value: value,
      color: color,
      /**
       * This will make the hasStroke cell wider than others.
       * TODO: Remove stroke and add gap Cell instead.
       */
      stroke: hasStroke ? 'white' : '',
      strokeWidth: hasStroke ? 1 : 0,
    });
  }
  return data;
};

type ScoreCircleProgressProps = {
  score: number;
  fullScore: number;
};
const CircleProgress = ({ score, fullScore }: ScoreCircleProgressProps) => {
  const data = getProgressDataFromScore(score, fullScore).reverse();
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          data={data}
          innerRadius={60}
          outerRadius={90}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
          startAngle={90}
          endAngle={450}
          isAnimationActive={false}
        >
          {data.map(({ color, stroke, strokeWidth }, index) => (
            <Cell key={`cell-${index}`} fill={color} stroke={stroke} strokeWidth={strokeWidth} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

const ScoreCircleProgress = ({ score, fullScore }: ScoreCircleProgressProps) => {
  return (
    <div className="h-[210px] w-[210px] relative">
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center">
        <Text variant="4xl" className="text-primary font-bold">
          {formatScore(score)}
        </Text>
        <Text variant="sm">จาก 5 คะแนน</Text>
      </div>
      <CircleProgress score={score > 0 ? score : 0} fullScore={fullScore} />
    </div>
  );
};

export default ScoreCircleProgress;
