import React from 'react';
import NavBar from './components/NavBar';

const PrintLayout = ({ children }: { children?: React.ReactNode }) => {
  return (
    <div>
      <div className="print:hidden">
        <div className="text-white text-center py-2 px-2 bg-green-500 font-semibold text-xs">
          Internal page
        </div>
        <NavBar />
      </div>
      {children}
    </div>
  );
};

export default PrintLayout;
