import React from 'react';
import { BaseProps } from '../../types';
import { toClassNames } from '../../../utils';
import style from './Form.module.css';

interface InputProps extends BaseProps {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  placeholder: string;
  disabled?: boolean;
}

const Input = ({ value, onChange, disabled, placeholder, className }: InputProps) => {
  return (
    <input
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      className={toClassNames([style.base, style.input, className])}
    />
  );
};

export default Input;
