import dayjs from 'dayjs';
import { Report, Sex } from '../../../types/report';
import style from './Template2.module.css';
import TableScore from '../components/TableScore';
import { School } from '../../../types/school';

const StudentInfoListItem = ({ label, value }: { label: string; value: string }) => {
  return (
    <p>
      <span style={{ fontWeight: 600 }}>{label}:</span> {value}
    </p>
  );
};

type StudentInfoProps = {
  fullName: string;
  school: string;
  grade: string;
  room?: string;
  sex: Sex;
  testDate: Date;
  testerId?: string;
};

const StudentInfo = ({
  sex,
  fullName,
  school,
  grade,
  room,
  testDate,
  testerId,
}: StudentInfoProps) => {
  return (
    <div className={style['report-print-student-info']}>
      <div className={style['report-print-mascot-container']}>
        <img
          src={'/assets/images/rocket-avatar.svg'}
          alt="mascot"
          className={style['report-print-mascot']}
        />
      </div>
      <div>
        <StudentInfoListItem label="ชื่อ" value={fullName} />
        <StudentInfoListItem label="โรงเรียน" value={school} />
        <StudentInfoListItem label="ระดับชั้น" value={`${grade} ${room ? `ห้อง ${room}` : null}`} />
        <StudentInfoListItem label="วันที่สอบ" value={dayjs(testDate).format('D MMMM BBBB')} />
        {testerId && <StudentInfoListItem label="ID" value={testerId} />}
      </div>
    </div>
  );
};

const ReportsPrint = ({ report, school }: { report: Report; school: School }) => {
  if (!report) return null;
  return (
    <div
      className={style['report-print-wrapper']}
      style={{ backgroundImage: `url(${school.sumReportTemplate2BackgroundUrl})` }}
    >
      {/* <div className={style['report-print-container']}> */}
      <div className={style['report-print-inner-background']}>
        <div className={style['report-print-overall-info']}>
          <StudentInfo
            fullName={report.student.fullName}
            school={school.name}
            grade={report.student.grade}
            sex={report.student.sex}
            room={report.student.room}
            testDate={report.date}
            testerId={report.id}
          />
          <div className={style['report-print-qr-code-container']}>
            <h5 className={style['report-print-qr-code-desc']}>
              แสกน QR <br />
              เข้าสู่รายงานฉบับเต็ม
            </h5>
            <img
              src={
                school.sumReportQrToReportUrl
                  ? school.sumReportQrToReportUrl
                  : '/assets/images/edsy-report-qr-code.svg'
              }
              alt="qr-code"
              className={style['report-print-qr-code']}
            />
          </div>
        </div>

        <div className={style['report-print-overall-result']}>
          <h2 className={style['overall-result-title']}>
            ระดับทักษะ
            <span className="text-primary font-semibold">SPEAKING</span>
          </h2>
          <div className={style['overall-result-container']}>
            <div className={style['overall-result-item']}>
              <img
                src="/assets/images/cefr-logo.png"
                alt="cefr assessment"
                className={`${style['cefr-logo']} ${style['standard-logo']}`}
              />
              <div className={style['label']}>{report.result.englishStandard.cefr}</div>
            </div>
            <div className={style['overall-result-item']}>
              <img
                src="/assets/images/cambridge-assessment-logo.png"
                alt="cambridge assessment"
                className={`${style['cambridge-logo']} ${style['standard-logo']}`}
              />
              <div className={style['label']}>{report.result.englishStandard.cambridge}</div>
            </div>
          </div>
        </div>

        <TableScore
          category={report.result.category}
          totalScore={report.result.overall.score}
          showTotalScore={true}
        />

        <div className={`${style['report-print-detail-footer']}`}>
          <div className={style['footer-item']}>
            <h2 className={style['footer-item-title']}>เครื่องมือ EdSpeak ของ Edsy</h2>
            <div className={style['footer-item-content']}>
              พัฒนาโดยผู้เชี่ยวชาญของ Edsy จากมหาวิทยาลัย Carnegie Mellon (USA) และ Oxford (UK)
              ผ่านโครงการวิจัยที่ทำร่วมกับมหาวิทยาลัย KMUTT (มจธ. บางมด)
              โดยอ้างอิงงานวิจัยซึ่งใช้ระยะเวลากว่า 15 ปีขององค์กร ETS (ผู้สร้าง-ดำเนินการสอบ TOEFL
              และ TOEIC)
            </div>
          </div>
          <div className={`${style['footer-item']} flex gap-4`}>
            <div>
              <h2 className={`${style['footer-item-title']} text-primary`}>
                รายงานฉบับเต็มประกอบด้วย
              </h2>
              <div className={style['footer-item-content']}>
                <ul>
                  <li>
                    <span>ผลการประเมินสำหรับแต่ละตัวชี้วัดหลัก</span>
                  </li>
                  <li>
                    <span>
                      <strong>Personalized Recommendation</strong> แนวทางใน <br />
                      การพัฒนาแต่ละส่วนเพิ่มเติม
                    </span>
                  </li>
                  <li>
                    <span>
                      แนะนำ <strong>Resources</strong> ต่างๆ ที่เป็นประโยชน์
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default ReportsPrint;
