import {
  createBrowserRouter,
  RouteObject,
  RouterProvider as BaseRouterPrivder,
} from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import Error from '../routes/Error';
import routes from './routes';

const routesForRouter: RouteObject[] = Object.values(routes).map((route) => {
  const RouteComponent = route.component;
  const ErrorComponent = route.errorComponent || Error;
  const LayoutComponent = route.layoutComponent || MainLayout;
  return {
    path: route.path,
    element: (
      <LayoutComponent>
        <RouteComponent />
      </LayoutComponent>
    ),
    errorElement: <ErrorComponent />,
  };
});

const router = createBrowserRouter(routesForRouter);

const RouterProvider = () => {
  return <BaseRouterPrivder router={router} />;
};

export { RouterProvider, routes };
