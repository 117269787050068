import React from 'react';
import { useParams } from 'react-router-dom';
import { getReportsByStudent, getSchoolDataBySchoolCode } from '../../api';
import { Report } from '../../types/report';
import { Container, Loading } from '../../components/base';
import ReporDetailtView from './ReportDetail.view';
import { School } from '../../types/school';

const ReportDetail = () => {
  const params = useParams();
  const [loading, setLoading] = React.useState(true);
  const [report, setReport] = React.useState<Report | undefined>();
  const [school, setSchool] = React.useState<School>();

  React.useEffect(() => {
    const getReportData = async () => {
      if (params.reportId) {
        try {
          const report = await getReportsByStudent(params.reportId);
          setReport(report);
          setLoading(false);
        } catch (error: any) {
          console.error(error);
        }
      }
    };
    getReportData();
  }, [params.reportId]);

  /**
   * Separate getting schoolData code to not break the app when cannot get schoolData
   */
  React.useEffect(() => {
    const getSchoolData = async () => {
      const schoolCode = report?.student.schoolCode;
      if (schoolCode) {
        try {
          const schoolData = await getSchoolDataBySchoolCode(schoolCode);
          setSchool(schoolData);
        } catch (error: any) {
          console.error(error);
        }
      }
    };

    getSchoolData();
  }, [report?.student.schoolCode]);

  if (loading || !report)
    return (
      <Container className="p-8">
        <Loading />
      </Container>
    );
  return <ReporDetailtView report={report} school={school} />;
};

export default ReportDetail;
