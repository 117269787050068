import { FC } from 'react';
import { ResultCategoryName } from '../types/report';
import MainLayout from '../layouts/MainLayout';
import BlankLayout from '../layouts/BlankLayout';
import PrintLayout from '../layouts/PrintLayout';
import Home from '../routes/Home/Home';
import Error from '../routes/Error';
import ReportDetail from '../routes/ReportDetail';
import ReportDetailCategory from '../routes/ReportDetailCategory';
import SchoolHome from '../routes/SchoolHome/SchoolHome';
import SchoolReports from '../routes/SchoolReports';
import SchoolReportsPrint from '../routes/SchoolReportsPrint';
import { SummaryReportTemplate } from '../components/SummaryReportPrint';

type RouteData = {
  path: string;
  component: FC;
  errorComponent?: FC;
  layoutComponent?: FC;
  getRoute: (params?: any) => string;
};

enum RouteKeys {
  Home = 'Home',
  ReportDetail = 'ReportDetail',
  ReportDetailCategory = 'ReportDetailCategory',
  SchoolHome = 'SchoolHome',
  SchoolReports = 'SchoolReports',
  SchoolReportsPrint = 'SchoolReportsPrint',
}

type RoutesData = {
  [key in RouteKeys]: RouteData;
};

const routes: RoutesData = {
  [RouteKeys.Home]: {
    path: '/',
    component: Home,
    layoutComponent: MainLayout,
    errorComponent: Error,
    getRoute: () => '/',
  },
  [RouteKeys.ReportDetail]: {
    path: '/reports/:reportId',
    component: ReportDetail,
    layoutComponent: MainLayout,
    errorComponent: Error,
    getRoute: (reportId: string) => `/reports/${reportId}`,
  },
  [RouteKeys.ReportDetailCategory]: {
    path: '/reports/:reportId/category/:category',
    component: ReportDetailCategory,
    layoutComponent: MainLayout,
    errorComponent: Error,
    getRoute: ({ reportId, category }: { reportId: string; category: ResultCategoryName }) => {
      return `/reports/${reportId}/category/${category}`;
    },
  },
  [RouteKeys.SchoolHome]: {
    path: '/schools',
    component: SchoolHome,
    layoutComponent: MainLayout,
    errorComponent: Error,
    getRoute: () => `/schools`,
  },
  [RouteKeys.SchoolReports]: {
    path: '/schools/:schoolCode/reports',
    component: SchoolReports,
    layoutComponent: PrintLayout,
    errorComponent: Error,
    getRoute: (schoolCode: string) => `/schools/${schoolCode}/reports`,
  },
  [RouteKeys.SchoolReportsPrint]: {
    path: '/school/:schoolCode/reports/print',
    component: SchoolReportsPrint,
    layoutComponent: BlankLayout,
    errorComponent: Error,
    getRoute: ({
      schoolCode,
      templateType,
    }: {
      schoolCode: string;
      templateType: SummaryReportTemplate;
    }) => {
      return `/school/${schoolCode}/reports/print?templateType=${templateType}`;
    },
  },
};

export default routes;
